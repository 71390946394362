import React from "react"

import ControlPlane from "../../../static/icons/capability-control-plane.inline.svg"
import Logs from "../../../static/icons/capability-logs.inline.svg"
import Traces from "../../../static/icons/capability-traces.inline.svg"
import Metrics from "../../../static/icons/capability-metrics.inline.svg"
import Alerting from "../../../static/icons/capability-alerting.inline.svg"

import CustomerStories from "../../../static/icons/resource-customer-stories.inline.svg"
import Whitepaper from "../../../static/icons/resource-whitepaper.inline.svg"
import Blog from "../../../static/icons/resource-blog.inline.svg"
import Events from "../../../static/icons/resource-events.inline.svg"
import Docs from "../../../static/icons/resource-docs.inline.svg"
import Changelog from "../../../static/icons/resource-changelog.inline.svg"

export const tags = [
  { name: "Levitate", slug: "levitate" },
  { name: "High Cardinality", slug: "high-cardinality" },
  { name: "Last9 Engineering", slug: "last9-engineering" },
  { name: "Deep Dives", slug: "deep-dives" },
]

export const authorSocialLinks = [
  {
    id: 1,
    network: "facebook",
    link: "https://www.facebook.com/",
  },
  {
    id: 2,
    network: "twitter",
    link: "https://twitter.com/",
  },
]

export const navPlatform = [
  {
    name: "Control Plane",
    href: "/control-plane/",
    icon: <ControlPlane className="size-5" />,
    alt: "Control Plane: Manage your Telemetry Data",
    desc: "Don't sacrifice visibility for cost optimization.",
  },
  {
    name: "Logs",
    href: "/logs/",
    icon: <Logs className="size-5" />,
    alt: "Cloud Native Logs Explorer and Logs Management",
    desc: "Stream and analyze millions of logs per minute.",
  },
  {
    name: "Traces",
    href: "/traces/",
    icon: <Traces className="size-5" />,
    alt: "Cloud Native End-to-End Distributed Tracing",
    desc: "Unblock bottlenecks and unlock performance.",
  },
  {
    name: "Metrics",
    href: "/metrics/",
    icon: <Metrics className="size-5" />,
    alt: "High Cardinality Metrics Monitoring",
    desc: "Granular observability with high cardinality metrics.",
  },
  {
    name: "Alerting",
    href: "/alerting/",
    icon: <Alerting className="size-5" />,
    alt: "Anomaly Detection and Change Intelligence",
    desc: "Purpose-built alerting for high cardinality environments.",
  },
]
export const navResources = [
  // {
  //   name: "Customer Stories",
  //   description: "Read how customers use and trust Last9",
  //   href: "/customer-stories/",
  //   icon: <CustomerStories className="size-5" />,
  //   alt: "Customer Stories",
  // },
  // {
  //   name: "Blog",
  //   description:
  //     "Writings about the observability space and how Last9 approaches it.",
  //   href: "/blog/",
  //   icon: <Blog className="size-5" />,
  //   alt: "Blog",
  // },
  {
    name: "Documentation",
    description:
      "How Last9 works, what integrations we support, how to send us data, and other FAQs.",
    href: "https://docs.last9.io/",
    target: "_blank",
    icon: <Docs className="size-5" />,
    alt: "Documentation",
  },
  {
    name: "Events",
    description:
      "Meet Last9 team members worldwide at observability, Cloud Native, and DevOps conferencess.",
    href: "/events/",
    icon: <Events className="size-5" />,
    alt: "Events",
  },
  {
    name: "Changelog",
    description: "Updates and improvements to Last9",
    href: "/changelog/",
    icon: <Changelog className="size-5" />,
    alt: "Changelog",
  },
  {
    name: "Whitepaper",
    description: "Levitate — Scaling Metrics Maturity in a Cloud-Native World",
    href: "/whitepapers/scaling-metrics-maturity-in-a-cloud-native-world/",
    icon: <Whitepaper className="size-5" />,
    alt: "Whitepaper",
  },
]
