import React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import {
  BriefcaseIcon,
  UsersIcon,
  GlobeIcon,
  LightningBoltIcon,
  CloudIcon,
  DownloadIcon,
} from "@heroicons/react/solid"
import Layout from "../../components/common/layout"
import Seo from "../../components/common/seo"
import RelatedPosts from "../../components/blog/RelatedPosts"
import CompanyDetails from "../../components/customer-stories/CompanyProfile"
import Section from "../../components/customer-stories/Section"
import SectionPointCard from "../../components/customer-stories/SectionPointCard"
import LevitatePreFooter from "../../components/levitate/LevitatePreFooter"
import TrophyIcon from "../../components/common/custom-icons/TrophyIcon"
import BugAntIcon from "../../components/common/custom-icons/BugAntIcon"
import Stats from "../../components/customer-stories/Stats"
import QuoteDark from "../../components/common/QuoteDark"
// Import ClientLogo from "../../components/common/ClientLogo"
import { quotes } from "../../constants/quotes"

import akashSaxena from "../../../static/levitate-tsdb/akash-saxena.jpeg"

const heading =
  "Reliable Observability for 25+ million concurrent live-streaming viewers"
const companyDetails = {
  id: "hotstar",
  name: "hotstar",
  points: [
    {
      name: "Video Streaming",
      icon: <BriefcaseIcon className="h-5 w-5 text-slate-400 md:h-6 md:w-6" />,
    },
    {
      name: "300+ engineers",
      icon: <UsersIcon className="h-6 w-5 text-slate-400 md:h-6 md:w-6" />,
    },
    {
      name: "APAC",
      icon: <GlobeIcon className="h-6 w-5 text-slate-400 md:h-6 md:w-6" />,
    },
    {
      name: "Amazon Web Services",
      icon: <CloudIcon className="h-6 w-5 text-slate-400 md:h-6 md:w-6" />,
    },
  ],
}

const paragraphStyles = "text-base leading-7 tracking-[-0.18px] text-slate-600"
const h3Styles = "mb-[0.6em] mt-[1.6em] text-xl font-bold leading-none"

const Hotstar = ({ data }) => {
  return (
    <Layout>
      <div className="px-4">
        <div className="mx-auto grid w-full max-w-6xl grid-cols-12 items-center gap-y-8 border-b-2 border-solid border-slate-100 py-10 md:py-20 lg:gap-y-16">
          <div className="col-start-1 col-end-13 row-span-1 flex flex-col gap-6 md:row-auto lg:col-start-1 lg:col-end-9">
            <span className="text-slate-500">
              <Link to="/customer-stories/" className="underline">
                Customer Stories
              </Link>
            </span>
            <div className="flex flex-col gap-6">
              <h1 className="text-3xl font-black leading-none md:text-4xl lg:text-6xl">
                {heading}
              </h1>
              <a
                href="/customer-stories/Reliable Observability.pdf"
                className="flex h-10 w-fit items-center gap-2 rounded-full border-2 border-solid border-brand-green-200 pl-4 pr-5 text-center text-lg font-bold leading-5 text-brand-green-200"
                download
              >
                <DownloadIcon className="h-5 w-5" />
                Download PDF
              </a>
            </div>
          </div>
          <div className="col-start-1 col-end-13 row-span-1 lg:col-start-10 lg:col-end-13">
            <CompanyDetails companyDetails={companyDetails} hideLogo />
          </div>
        </div>
        <div className="mx-auto grid w-full max-w-6xl grid-cols-12 border-b-2 border-solid border-slate-100 py-10 md:py-20 lg:gap-y-16 [&_a]:underline">
          <div className="col-start-1 col-end-13 row-span-1 flex flex-col gap-20 md:row-auto lg:col-start-1 lg:col-end-9">
            {/** Section 1 */}
            <p className={paragraphStyles}>
              Last9 works with some of the world’s largest streaming companies.
              One of our customers shows movies, TV shows, and large-scale
              sporting events for its millions of subscribers. At{" "}
              <a className="underline" href="/cricket-scale">
                Cricket Scale
              </a>{" "}
              hundreds, not dozens, of microservices harmoniously stitch
              together compelling user experiences that keep viewers glued to
              their devices.
            </p>
            <Section
              heading="No dropped frames,
never miss a moment"
            >
              <>
                <p className={paragraphStyles}>
                  A cricket match can have over 25 million concurrent viewers.
                  Games last about 3-4 hours, and systems are warmed up hours in
                  advance in anticipation of the sudden surge in traffic.
                  Significant ephemeral resources come online to last the game’s
                  duration and be torn down soon after. Hundreds of engineers
                  work on backend services and a robust infrastructure to enable
                  the live-streaming of such high-ticketing events.
                </p>
                <p className={paragraphStyles}>
                  When something goes wrong, the war room team needs to
                  immediately isolate the issue. The next step is to draft a
                  Root Cause Analysis (RCA) and route it to the appropriate team
                  for further investigation. Every additional second that is
                  taken to diagnose a problem adversely impacts advertising
                  revenue. Above all, it causes massive viewer dissatisfaction,
                  given the criticality of missing a major sporting memory.
                  Teams often find themselves investigating leading indicators
                  of failure. These problems manifest on social media platforms,
                  and before they spread, need to be triaged and fixed.
                </p>
              </>
            </Section>
            {/** Section 1 end */}
            {/** Section 2 */}
            <Section
              heading="Growing Pains"
              icon={
                <BugAntIcon height={24} width={24} className="text-red-500" />
              }
            >
              <>
                <div className="mb-4 flex flex-col justify-center gap-3 md:flex-row">
                  <SectionPointCard
                    color="red"
                    title="Scale"
                    description="Scaling in-house metrics with business growth"
                  />
                  <SectionPointCard
                    color="red"
                    title="Uptime"
                    description="Maintaining uptime and query guarantees"
                  />
                  <SectionPointCard
                    color="red"
                    title="Toil"
                    description="Managing a TSDB instead of focusing on Product"
                  />
                  <SectionPointCard
                    color="red"
                    title="Standardization"
                    description="Standard Telemetry across teams"
                  />
                </div>
                <h3 className={h3Styles}>
                  The Multifaceted Infrastructure Platform
                </h3>
                <p className={paragraphStyles}>
                  A diverse and complex infrastructure platform powers our
                  customer’s scale. Hundreds of microservices and a variety of
                  data stores handle persistent data storage; some are fully
                  managed by Cloud Hyperscalers, and the in-house team manages
                  others. The scale-up necessitated by such games results in
                  many ephemeral resources coming online. Consistent and uniform
                  Observability across these disparate sources is incredibly
                  challenging; to observe their health all of these sources
                  continuously emit metrics and in plenty. Over time, the team’s
                  engineers noticed a sprawl of metrics and monitoring
                  techniques, making it hard to standardize the telemetry and
                  monitoring.
                </p>
                <p className={paragraphStyles}>
                  The team was using an in-house setup based on VictoriaMetrics
                  (a popular open-source time series database) and InfluxDB for
                  metrics management. For visualizing data and managing alerts,
                  Grafana was used.
                </p>
                <h3 className={h3Styles}>
                  Growing scale and concurrent access woes
                </h3>
                <p className={paragraphStyles}>
                  Thousands of dashboards were created by multiple teams
                  presenting unique challenges. Grafana dashboards and alerts
                  were concurrently accessing the same underlying metrics
                  storage. These underlying databases could not keep up with
                  massive ingestion and simultaneous queries. Inevitably, the
                  storage would ultimately go down leaving the teams oblivious
                  to the health of their infrastructure. Instead of focusing on
                  features and innovating on the product, the engineering team
                  spent countless hours keeping the Observability platform up.
                </p>
                <p className={paragraphStyles}>
                  To reliably support the team’s incredible infrastructure
                  growth, they needed a next-generation Observability platform.
                  Given their unique challenges and incredible scale, the team
                  needed a product that could withstand{" "}
                  <Link to="/blog/understanding-cricket-scale/">
                    “<span className="underline">cricket scale</span>”
                  </Link>
                  , sustain uptime, be globally available, and not explode
                  costs.
                </p>
              </>
            </Section>
            {/** Section 2 end */}
            {/** Section 3 */}
            <Section
              heading="The Last9 Advantage"
              icon={
                <TrophyIcon
                  height={24}
                  width={24}
                  className="text-emerald-500"
                />
              }
            >
              <>
                <div className="mb-4 flex flex-col justify-center gap-3 md:flex-row">
                  <SectionPointCard
                    color="emerald"
                    title="Open Standards"
                    description="Zero integration efforts"
                  />
                  <SectionPointCard
                    color="emerald"
                    title="Superfast Ingestion"
                    description="50% reduction in write latency"
                  />
                  <SectionPointCard
                    color="emerald"
                    title="Data Tiering"
                    description="Solves concurrent access woes and powers long term retention"
                  />
                </div>
                <p className={paragraphStyles}>
                  Last9’s Levitate is a globally available time series & events
                  warehouse designed for scale, high cardinality and long term
                  retention.
                </p>
                <h3 className={h3Styles}>Open Standards</h3>
                <p className={paragraphStyles}>
                  Levitate ingests data from multiple open standards, such as
                  Prometheus exposition, OpenTelemetry Metrics, OpenMetrics, and
                  InfluxDB. This ensured no migration effort was needed at our
                  customer’s scale of hundreds of micro-services. Hundreds of
                  engineers were onboarded to existing and new workloads on
                  Levitate within weeks, given interoperability and ease of
                  integration. Since Levitate is fully compatible with Open
                  standards on the output layer, the team could keep using their
                  existing dashboards and alerting workflows.
                </p>
                <p className={paragraphStyles}>
                  Within a month, Levitate was the source of truth for all
                  metrics workloads across our customers’ teams.
                </p>
                <h3 className={h3Styles}>SLA Guarantees</h3>
                <p className={paragraphStyles}>
                  Levitate is a managed service with Service Level Agreement
                  (SLA) guarantees and clawbacks for both Read and Write
                  workloads. This eliminated the toil and upkeep to manage and
                  scale our customer’s in-house metrics setup.
                </p>
                <Stats
                  heading="Levitate’s Availability SLA Guarantees"
                  stats={[
                    {
                      title: "write",
                      stat: "99.99%",
                    },
                    {
                      title: "read",
                      stat: "99.95%",
                    },
                  ]}
                />
                <h3 className={h3Styles}>Long Term Retention</h3>
                <p className={paragraphStyles}>
                  With the previous in-house metrics setup, teams could not
                  retain data beyond a month for critical analysis. Imagine
                  having billions of data points of consumer behavior, but being
                  unable to use them for growing business needs.
                </p>
                <p className={paragraphStyles}>
                  Levitate’s automatic data tiering and retention policies paved
                  the way for long-term time series storage. This helped the
                  team with capacity planning and business insights year after
                  year. By default, the latest data is available in all tiers,
                  but their retention policies vary.
                </p>
                <Stats
                  heading="Levitate’s Default Tier Retention"
                  stats={[
                    {
                      title: "Blaze tier",
                      stat: "3 hours",
                    },
                    {
                      title: "hot tier",
                      stat: "6 months",
                    },
                    {
                      title: "cold tier",
                      stat: "1 year",
                    },
                  ]}
                />
                <p className={paragraphStyles}>
                  Levitate’s Data Tiering capability is also used on the query
                  layer, creating policies for accessing the Blaze tier only for
                  alerting. The other tiers can then be used for deeper
                  exploration and analysis. This resolved the concurrent access
                  issue they faced with the in-house metric setup.
                </p>
                <QuoteDark
                  author={{
                    name: "Akash Saxena",
                    link: "https://www.linkedin.com/in/akisaxena/",
                    img: akashSaxena,
                  }}
                  quote={
                    <p className="text-center text-base leading-6 tracking-[-0.33px] text-white md:text-xl md:leading-7">
                      {quotes["akash-saxena"]}{" "}
                      <Link
                        to="/blog/observability-and-sre-at-hotstar-with-last9/"
                        className="text-xs hover:underline"
                      >
                        Read More <sup>↗</sup>
                      </Link>
                    </p>
                  }
                />
              </>
            </Section>
            {/** Section 3 end */}
            {/** Section 4 */}
            <Section
              heading="Key Results"
              icon={
                <LightningBoltIcon
                  width={24}
                  height={24}
                  className="text-blue-500"
                />
              }
            >
              <div className="mb-4 flex flex-col justify-center gap-3 md:flex-row">
                <SectionPointCard
                  color="blue"
                  title="Single Source of Truth"
                  description="Single data source for all metrics workloads"
                />
                <SectionPointCard
                  color="blue"
                  title="Zero Toil, Better Performance"
                  description="No toil of managing an in-house TSDB"
                />
                <SectionPointCard
                  color="blue"
                  title="Reduced TCO"
                  description="Total Cost of Ownership reduced by 50%"
                />
              </div>
              <p className={paragraphStyles}>
                Levitate has improved query speeds, dramatically reduced the
                Total Cost of Ownership (TCO) by 50%, and is currently the
                bedrock for the customers’ entire infrastructure.
              </p>
              <h3 className={h3Styles}>Bring Your Own Cloud Model</h3>
              <p className={paragraphStyles}>
                Levitate comes with a Bring Your Own Cloud (BYOC) model - we can
                deploy in our customers’ cloud directly offering all the
                features Levitate comes with.
              </p>
              <p className={paragraphStyles}>
                Levitate is currently the bedrock for the customers’ entire
                infrastructure.
              </p>
              <p className={paragraphStyles}>
                With optimized auto-tiered storage, warehousing control levers,
                and availability guarantees, we’ve reduced the toil of managing
                a time series database and the engineering overheads that come
                with it — something seldom factored in while calculating the
                cost of running your own Observability team.
              </p>
            </Section>
            {/** Section 4 end */}
            <div className="border-t-2 border-solid border-slate-100 pt-10 md:pt-20">
              <p className={paragraphStyles}>
                Talk to the Last9 team to understand how Levitate can unlock
                value for you as well.{" "}
                <Link className="underline" to="/schedule-demo">
                  Get a demo
                </Link>{" "}
                or{" "}
                <a
                  href="https://app.last9.io/"
                  className="underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  get started
                </a>{" "}
                today.
              </p>
            </div>
          </div>
          {/** Sticky section */}
          <div className="relative col-start-1 col-end-13 row-span-1 hidden lg:col-start-10 lg:col-end-13 lg:block">
            <div className="sticky top-36 w-full divide-y-2 divide-emerald-200 rounded-3xl bg-emerald-50 px-8 py-6">
              <div className="pb-8 text-emerald-600">
                <span className="mb-3 block text-sm font-bold uppercase">
                  Key points
                </span>
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-1">
                    <span className="text-2xl font-bold leading-8">
                      Single source
                    </span>
                    <span className="text-xs font-bold uppercase">
                      for all metric workloads
                    </span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-2xl font-bold leading-8">50%</span>
                    <span className="text-xs font-bold uppercase">
                      reduced tco
                    </span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-2xl font-bold leading-8">
                      Zero Toil
                    </span>
                    <span className="text-xs font-bold uppercase">
                      with better performance
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start gap-3 pt-8">
                <span className="text-sm font-bold uppercase leading-5 text-slate-500">
                  get a demo
                </span>
                <p className="text-sm leading-5 text-slate-500">
                  Talk to us to understand how Levitate can reduce costs, and
                  manage cardinality.
                </p>
                <Link
                  to="/schedule-demo"
                  className="flex cursor-pointer items-center justify-center rounded-full bg-emerald-500 px-6 py-2.5 font-bold text-white !no-underline"
                >
                  Schedule
                </Link>
              </div>
            </div>
          </div>
          {/** Sticky section end */}
        </div>
      </div>
      <div className="px-4">
        <RelatedPosts
          relatedPosts={data.relatedPosts.nodes.map(node => {
            return {
              ...node,
              featureImage: node.feature_image,
            }
          })}
          bgColor="bg-white"
        />
      </div>
      <LevitatePreFooter />
    </Layout>
  )
}

Hotstar.propTypes = {
  data: PropTypes.shape({
    relatedPosts: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
}

export const query = graphql`
  query MyQuery {
    relatedPosts: allGhostPost(
      filter: {
        slug: {
          in: [
            "understanding-cricket-scale"
            "observability-oss-vs-paid-vs-managed-oss"
            "taking-back-control-of-your-monitoring"
          ]
        }
      }
    ) {
      nodes {
        published_at
        authors {
          name
          profile_image
          id
        }
        title
        excerpt
        slug
        id
        feature_image
      }
    }
  }
`

export const Head = () => (
  <Seo
    title={heading}
    description="How we've tackled high cardinality metrics with long-term retention for one of the largest video streaming companies."
    image="/thumbnails/thumbnail-last9-cust-story-video-streaming.jpg"
  />
)

export default Hotstar
