import React from "react"
import { Link } from "gatsby"
import levitateIcon from "../../../static/home/home-levitate-icon.svg"
import Balancer from "react-wrap-balancer"
import CostQuote from "../quotes/CostQuote"

const Hero = () => {
  return (
    <div className="mx-auto block max-w-6xl">
      {/* icon */}
      <img
        src={levitateIcon}
        alt="Levitate by Last9"
        className="h-14 w-14 md:h-20 md:w-20"
      />
      {/* end icon */}

      {/* body */}
      <div className="mb-40 mt-10 flex flex-col items-center gap-8 md:flex-row">
        {/* left section */}
        <div className="flex flex-col gap-8 md:basis-1/2 lg:basis-auto">
          {/* text */}
          <div className="flex flex-col gap-6 text-slate-600">
            <h2 className="text-4xl font-black tracking-tight text-black md:text-6xl">
              Levitate. A Time Series Data Warehouse.
            </h2>
            <div className="flex flex-col gap-4 text-base md:text-xl">
              <p className="text-2xl font-bold">
                <Balancer>
                  Do away with the toil of managing your own Prometheus.
                </Balancer>
              </p>
              <p>Scale Reliably, Slash Costs & Tame High Cardinality.</p>
            </div>
          </div>
          {/* end text */}

          {/* buttons */}
          <div className="flex items-center justify-center gap-4 md:justify-start">
            <Link
              to="/schedule-demo"
              className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-slate-900 px-6 text-center text-base font-bold text-white"
            >
              Talk to Us
            </Link>
            <a
              href="https://app.last9.io/"
              className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-slate-100 px-6 text-center text-base font-bold text-slate-500"
            >
              Start for Free
            </a>
          </div>
          {/* end buttons */}
        </div>
        {/* end left section */}

        <CostQuote />
      </div>
      {/* end body */}
    </div>
  )
}

export default Hero
