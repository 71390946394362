import React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import {
  BriefcaseIcon,
  UsersIcon,
  GlobeIcon,
  LightningBoltIcon,
  CloudIcon,
  DownloadIcon,
  ChartBarIcon,
} from "@heroicons/react/solid"
import Layout from "../../components/common/layout"
import Seo from "../../components/common/seo"
import RelatedPosts from "../../components/blog/RelatedPosts"
import CompanyDetails from "../../components/customer-stories/CompanyProfile"
import Section from "../../components/customer-stories/Section"
import SectionPointCard from "../../components/customer-stories/SectionPointCard"
import LevitatePreFooter from "../../components/levitate/LevitatePreFooter"
import TrophyIcon from "../../components/common/custom-icons/TrophyIcon"
import BugAntIcon from "../../components/common/custom-icons/BugAntIcon"

import dashboardImg from "../../../static/customer-stories/quickwork/dashboard.png"
import flowchartImg from "../../../static/customer-stories/quickwork/flowchart.png"
import krishAdvani from "../../../static/customer-stories/quickwork/krish-advani.jpeg"
import QuoteDark from "../../components/common/QuoteDark"

const heading =
  "Quickwork champions platform transparency for its customers with Last9"
const companyDetails = {
  id: "quickwork",
  name: "quickwork",
  points: [
    {
      name: "Realtime Workflow Platform",
      icon: <BriefcaseIcon className="h-5 w-5 text-slate-400 md:h-6 md:w-6" />,
    },
    {
      name: "650M transactions/day",
      icon: <ChartBarIcon className="h-6 w-5 text-slate-400 md:h-6 md:w-6" />,
    },
    {
      name: "APAC",
      icon: <GlobeIcon className="h-6 w-5 text-slate-400 md:h-6 md:w-6" />,
    },
    {
      name: "Amazon Web Services",
      icon: <CloudIcon className="h-6 w-5 text-slate-400 md:h-6 md:w-6" />,
    },
  ],
}

const keyPoints = [
  {
    title: "High Cardinality",
    description: "Per-customer granular metrics",
  },
  {
    title: "Reliability",
    description: "Monitoring 50M transactions a day per customer",
  },
  {
    title: "Cost",
    description: "90% more cost-efficient than Datadog",
  },
]

const last9Advantage = [
  {
    title: "Purpose-built for High Cardinality",
    description:
      "Ground-up telemetry warehouse intended for high-dimensionality data",
  },
  {
    title: "Proven Scale & Reliability",
    description:
      "Cricket-scale traffic support for customers with 400M samples/min and 50M concurrent users",
  },
  {
    title: "No Cost Surprises",
    description:
      "Predictable single-metric billing mode based on the number of samples ingested",
  },
]

const keyResults = [
  {
    title: "Per Customer Observability",
    description: "Ability to provide real-time metrics to each customer",
  },
  {
    title: "Improved Granular Monitoring",
    description: "Workflow health metrics beyond successes and failures",
  },
  {
    title: "Higher Customer Satisfaction",
    description:
      "Better transparency of workflow health for proactive management",
  },
  {
    title: "Increased ROI",
    description:
      "Improved reliability, yet cost-effective, and ease of portability due to Open Standards support",
  },
]

const paragraphStyles = "text-base leading-7 tracking-[-0.18px] text-slate-600"

const Quickwork = ({ data }) => {
  return (
    <Layout>
      <div className="px-4">
        <div className="mx-auto grid w-full max-w-6xl grid-cols-12 items-center gap-y-8 border-b-2 border-solid border-slate-100 py-10 md:py-20 lg:gap-y-16">
          <div className="col-start-1 col-end-13 row-span-1 flex flex-col gap-6 md:row-auto lg:col-start-1 lg:col-end-9">
            <span className="text-slate-500">
              <Link to="/customer-stories/" className="underline">
                Customer Stories
              </Link>
            </span>
            <div className="flex flex-col gap-6">
              <h1 className="text-3xl font-black leading-none md:text-4xl lg:text-6xl">
                {heading}
              </h1>
              <a
                href="/customer-stories/last9-levitate-customer-story-quickwork.pdf"
                className="flex h-10 w-fit items-center gap-2 rounded-full border-2 border-solid border-brand-green-200 pl-4 pr-5 text-center text-lg font-bold leading-5 text-brand-green-200"
                download
              >
                <DownloadIcon className="h-5 w-5" />
                Download PDF
              </a>
            </div>
          </div>
          <div className="col-start-1 col-end-13 row-span-1 lg:col-start-10 lg:col-end-13">
            <CompanyDetails companyDetails={companyDetails} />
          </div>
        </div>
        <div className="mx-auto grid w-full max-w-6xl grid-cols-12 border-b-2 border-solid border-slate-100 py-10 md:py-20 lg:gap-y-16 [&_a]:underline">
          <div className="col-start-1 col-end-13 row-span-1 flex flex-col gap-20 md:row-auto lg:col-start-1 lg:col-end-9">
            {/** Section 1 */}
            <div className="flex flex-col gap-5">
              <p className={paragraphStyles}>
                Quickwork is a no-code, enterprise-grade integration, and
                automation platform (iPaaS) that enables developers and
                companies to build workflows, publish APIs, and manage
                conversational interactions with customers, employees, and
                partners.
              </p>
              <p className={paragraphStyles}>
                It integrates with over 1,500 applications across various
                domains, such as business, consumer, AI, analytics, messaging,
                and IoT, facilitating seamless data exchange to automate tasks
                in real-time without human intervention.
              </p>
              <p className={paragraphStyles}>
                Many reputed enterprise customers like Axis Bank, Yes Bank,
                Unity Bank, Lupin, DMI Finance, Google Pay, and Samsung Pay use
                the Quickwork integration platform to process high volume
                transactions in real-time. Quickwork, with 10,000 workflows and
                650 million transactions per day, wanted to provide transparency
                and visibility to their customers on the platform’s performance
                for better platform capacity utilisation and provisioning.
              </p>
            </div>
            {/** Section 1 end */}

            {/** Section 2 */}
            <Section
              heading="Growing Pains"
              icon={
                <BugAntIcon height={24} width={24} className="text-red-500" />
              }
            >
              <>
                <div className="mb-4 flex flex-col justify-center gap-3 md:flex-row">
                  <SectionPointCard
                    color="red"
                    title="High Cardinality"
                    description="Per customer monitoring of the platform"
                  />
                  <SectionPointCard
                    color="red"
                    title="Scale"
                    description="50M transactions per day across customers"
                  />
                  <SectionPointCard
                    color="red"
                    title="Cost"
                    description="Per customer visibility becoming cost-prohibitive"
                  />
                  <SectionPointCard
                    color="red"
                    title="Reliability"
                    description="Visualization and alerting high-cardinality data"
                  />
                </div>
                <p className={paragraphStyles}>
                  The team at Quickwork is obsessed about customer satisfaction,
                  and they wanted to resolve the above pain points by providing
                  performance insights to their customers on certain KPIs
                  related to workflows:
                </p>
                <ul className="flex list-disc flex-col gap-4 pl-6 text-base leading-7 marker:text-red-500 marker:content-['—'] [&_li]:pl-4">
                  <li>Consumption</li>
                  <li>Concurrency Quota</li>
                  <li>CPU Quota</li>
                  <li>Memory Quota</li>
                  <li>Quality of Service and Latency</li>
                </ul>
                <p className={paragraphStyles}>
                  While putting together a plan for customer-level
                  instrumentation of these metrics, the team also identified the
                  challenges of dealing with the resultant high-cardinality data
                  — leading to increased complexity in the use case, and with
                  growing customers, their in-house setup was proving difficult
                  to scale.
                </p>
                <p className={paragraphStyles}>
                  Prometheus’ documentation discourages users from overusing
                  labels; it is infamous for choking up when writing and reading
                  high-cardinality data. Further, with serverless architecture,
                  the container-based instrumentation of Prometheus runs into
                  challenges like being unable to set up alerts for a large pool
                  of ephemeral components. This leads to customers’ lack of
                  visibility into platform performance metrics.
                </p>
                <p className={paragraphStyles}>
                  Quickwork provides extra buffer capacity to help increase
                  transaction volumes based on the customer’s plan, and they
                  wanted to eliminate the extra provisioning by giving customers
                  visibility into how they use the Quickwork platform, giving
                  insights into consumption patterns.
                </p>
                <p className={paragraphStyles}>
                  As they roll out monitoring for their customers, they intend
                  to extend the observability from a per-workflow basis to a
                  per-step basis, further increasing the cardinality of the
                  data. With the plan to enable their customers to set up alerts
                  on their data, the need for a purpose-built high-cardinality
                  telemetry store that is efficient and cost-effective becomes
                  more apparent.
                </p>
              </>
            </Section>
            {/** Section 2 end */}

            {/** Section 3 */}
            <Section
              heading="The Last9 Advantage"
              icon={
                <TrophyIcon
                  height={24}
                  width={24}
                  className="text-emerald-500"
                />
              }
            >
              <>
                <div className="mb-4 flex flex-col justify-center gap-3 md:flex-row">
                  {last9Advantage.map((point, index) => (
                    <SectionPointCard
                      color="emerald"
                      title={point.title}
                      description={point.description}
                    />
                  ))}
                </div>
                <p className={paragraphStyles}>
                  High cardinality data is essential for achieving granular
                  observability. Hence, Last9 was built to ingest such data, and
                  query and alert on it. Each metric has a default quota of 20M
                  time series daily, which can be increased upon request. Last9
                  also has no limits on the number of metrics, while custom
                  metrics in tools like Datadog inflate costs significantly,
                  leading to teams adding instrumentation constraints.
                </p>
                <p className={paragraphStyles}>
                  Having powered monitoring for large-scale live streaming
                  events, Last9 gave Quickwork confidence that their needs can
                  be supported — to enable per-customer monitoring for their
                  engineering team and provide visibility into per-workflow
                  metrics for their customers.
                </p>
                <p className={paragraphStyles}>
                  <a
                    className="underline"
                    href="https://docs.last9.io/docs/cardinality-explorer"
                  >
                    Cardinality Explorer
                  </a>
                  ’s streamlined and unified experience for identifying impacted
                  metrics and their label values, combined with Streaming
                  Aggregation to control such metrics at the ingestion level,
                  gives the Quickwork team unprecedented control of their
                  telemetry without much instrumentation change.
                </p>
                <p className={paragraphStyles}>
                  Built to support Open Standards, starting the PoC on Levitate
                  became as easy as changing a Prometheus configuration file for
                  Quickwork.
                </p>
                <p className={paragraphStyles}>
                  The Quickwork team duplicated their existing Grafana
                  dashboards, simply changed the source URL to point to
                  Levitate, and they were up and ready for review. Over the
                  three weeks, as they reviewed performance and scale, they
                  could start deprecating the monitoring infra on their end with
                  bare minimum code changes.
                </p>

                <figure>
                  <img
                    src={flowchartImg}
                    loading="lazy"
                    className="aspect-auto w-full rounded-lg border border-slate-200"
                    alt="architecture diagram of quickwork and last9 levitate data flow"
                  />
                  <figcaption className="text-left text-slate-400">
                    How data flows between Quickwork and Last9 Levitate
                  </figcaption>
                </figure>

                <p className={paragraphStyles}>
                  Quickwork’s workflow journey metrics are scraped and pushed to
                  Levitate to enable per-workflow monitoring for their
                  customers. The Last9 API is then used to fetch metrics from
                  the Telemetry Data Warehouse and configure & evaluate alert
                  configurations from Alert Studio to power both:
                </p>
                <ul className="flex list-disc flex-col gap-4 pl-6 text-base leading-7 marker:text-emerald-500 marker:content-['—'] [&_li]:pl-4">
                  <li>
                    the Grafana dashboards and alerting used by Quickwork’s
                    engineering team, and
                  </li>
                  <li>
                    the Quickwork iPaaS dashboard is used by its customers to
                    monitor and set alerts on their workflow metrics
                  </li>
                </ul>

                <figure>
                  <img
                    src={dashboardImg}
                    loading="lazy"
                    className="aspect-auto w-full rounded-lg border border-slate-200"
                    alt="quickwork dashboard with metrics powered by last9 levitate"
                  />
                  <figcaption className="text-left text-slate-400">
                    Quickwork iPaaS dashboard with metrics powered by Last9
                    Levitate
                  </figcaption>
                </figure>

                <QuoteDark
                  quote={
                    <p className="px-10">
                      The ease with which Levitate does the heavy lifting for us
                      with high-cardinality data at massive scale is phenomenal.
                      Enabling per-customer monitoring and making the metrics
                      visible to our customers in their dashboards to improve
                      CSAT became a no-brainer.
                    </p>
                  }
                  author={{
                    name: "Krish Advani, Co-founder & CTO, Quickwork",
                    img: krishAdvani,
                  }}
                />
              </>
            </Section>
            {/** Section 3 end */}

            {/** Section 4 */}
            <Section
              heading="Key Results"
              icon={
                <LightningBoltIcon
                  width={24}
                  height={24}
                  className="text-blue-500"
                />
              }
            >
              <div className="mb-4 flex flex-col justify-center gap-3 md:flex-row">
                {keyResults.map((point, index) => (
                  <SectionPointCard
                    color="blue"
                    title={point.title}
                    description={point.description}
                  />
                ))}
              </div>
              <p className={paragraphStyles}>
                After a quick three-week POC, the Quickwork team was able to
                finalize Levitate as the solution to power their embedded
                monitoring on a per-customer level. Today, their customer
                dashboards go beyond transaction counts and workflow successes
                and failures to include concurrency, status codes, response
                time, and more, along with the ability to set up alerts to
                ensure customer satisfaction and higher CSAT scores.
              </p>
            </Section>
            {/** Section 4 end */}

            <hr />
            <p className={paragraphStyles}>
              <Link className="underline" to="/schedule-demo">
                Schedule a demo
              </Link>{" "}
              to understand how engineering teams at Quickwork, Clevertap,
              Replit, and more are using Last9 Levitate to enable SaaS
              monitoring.
            </p>
          </div>

          {/** Sticky section */}
          <div className="relative col-start-1 col-end-13 row-span-1 hidden lg:col-start-10 lg:col-end-13 lg:block">
            <div className="sticky top-36 w-full divide-y-2 divide-emerald-200 rounded-3xl bg-emerald-50 px-8 py-6">
              <div className="pb-8 text-emerald-600">
                <span className="mb-3 block text-sm font-bold uppercase">
                  Key points
                </span>
                <div className="flex flex-col gap-6">
                  {keyPoints.map((point, index) => (
                    <div className="flex flex-col gap-1">
                      <span className="text-2xl font-bold leading-8">
                        {point.title}
                      </span>
                      <span className="text-balance text-xs font-bold uppercase">
                        {point.description}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col items-start gap-3 pt-8">
                <span className="text-sm font-bold uppercase leading-5 text-slate-500">
                  get a demo
                </span>
                <p className="text-sm leading-5 text-slate-500">
                  Talk to us to understand how Levitate can reduce costs, and
                  manage cardinality.
                </p>
                <Link
                  to="/schedule-demo"
                  className="flex cursor-pointer items-center justify-center rounded-full bg-emerald-500 px-6 py-2.5 font-bold text-white !no-underline"
                >
                  Schedule
                </Link>
              </div>
            </div>
          </div>
          {/** Sticky section end */}
        </div>
      </div>
      <div className="px-4">
        <RelatedPosts
          relatedPosts={data.relatedPosts.nodes.map(node => {
            return {
              ...node,
              featureImage: node.feature_image,
            }
          })}
          bgColor="bg-white"
        />
      </div>
      <LevitatePreFooter />
    </Layout>
  )
}

Quickwork.propTypes = {
  data: PropTypes.shape({
    relatedPosts: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
}

export const query = graphql`
  query MyQuery {
    relatedPosts: allGhostPost(
      filter: {
        slug: {
          in: [
            "understanding-cricket-scale"
            "observability-oss-vs-paid-vs-managed-oss"
            "taking-back-control-of-your-monitoring"
          ]
        }
      }
    ) {
      nodes {
        published_at
        authors {
          name
          profile_image
          id
        }
        title
        excerpt
        slug
        id
        feature_image
      }
    }
  }
`

export const Head = () => (
  <Seo
    title={heading}
    description="Read how Quickwork improved their CSAT using Last9 for high cardinality observability and providing its customers a real-time monitoring dashboard."
    image="/thumbnails/thumbnail-last9-cust-story-quickwork.jpg"
  />
)

export default Quickwork
