import React from "react"
import { Link } from "gatsby"
import * as NavigationMenu from "@radix-ui/react-navigation-menu"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { navPlatform, navResources } from "./data"
import Platform from "./navigation/platform"
import Resources from "./navigation/resources"

const Navigation = () => {
  return (
    <div className="navigation">
      <NavigationMenu.Root className="relative z-10 hidden lg:flex">
        <NavigationMenu.List className="flex items-center gap-1">
          {/* Platform Dropdown */}
          <NavigationMenu.Item>
            <NavigationMenu.Trigger className="group flex select-none items-center justify-between gap-1 rounded-md p-3 text-base font-medium text-slate-600 outline-none hover:text-slate-800 focus:text-slate-800">
              Platform
              <ChevronDownIcon
                className="h-4 w-4 text-slate-400 transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
                aria-hidden
              />
            </NavigationMenu.Trigger>
            <NavigationMenu.Content className="absolute left-0 w-full data-[motion=from-end]:animate-enterFromRight data-[motion=from-start]:animate-enterFromLeft data-[motion=to-end]:animate-exitToRight data-[motion=to-start]:animate-exitToLeft sm:w-auto">
              <Platform cases={navPlatform} />
            </NavigationMenu.Content>
          </NavigationMenu.Item>

          {/* Resources Dropdown */}
          <NavigationMenu.Item>
            <NavigationMenu.Trigger className="group flex select-none items-center justify-between gap-1 rounded-md p-3 text-base font-medium text-slate-600 outline-none hover:text-slate-800 focus:text-slate-800">
              Resources
              <ChevronDownIcon
                className="h-4 w-4 text-slate-400 transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
                aria-hidden
              />
            </NavigationMenu.Trigger>
            <NavigationMenu.Content className="absolute left-0 top-0 w-full data-[motion=from-end]:animate-enterFromRight data-[motion=from-start]:animate-enterFromLeft data-[motion=to-end]:animate-exitToRight data-[motion=to-start]:animate-exitToLeft sm:w-auto">
              <Resources resources={navResources} />
            </NavigationMenu.Content>
          </NavigationMenu.Item>

          {/* Regular Links */}
          <NavigationMenu.Item>
            <NavigationMenu.Link asChild>
              <Link
                to="/customer-stories/"
                className="block select-none rounded-md p-3 text-base font-medium text-slate-600 outline-none hover:text-slate-800 focus:text-slate-800"
              >
                Customers
              </Link>
            </NavigationMenu.Link>
          </NavigationMenu.Item>

          <NavigationMenu.Item>
            <NavigationMenu.Link asChild>
              <Link
                to="/blog/"
                className="block select-none rounded-md p-3 text-base font-medium text-slate-600 outline-none hover:text-slate-800 focus:text-slate-800"
              >
                Blog
              </Link>
            </NavigationMenu.Link>
          </NavigationMenu.Item>

          <NavigationMenu.Indicator className="top-full z-10 flex h-3 items-end justify-center overflow-visible transition-[width,transform_250ms_ease] data-[state=hidden]:animate-fadeOut data-[state=visible]:animate-fadeIn">
            <div className="relative -top-0.5 size-3 rotate-45 rounded-tl-sm bg-white" />
          </NavigationMenu.Indicator>
        </NavigationMenu.List>

        <div className="absolute left-0 top-11 flex w-full justify-center perspective-[2000px] sm:-left-1/4 sm:w-[var(--radix-navigation-menu-viewport-width)]">
          <NavigationMenu.Viewport className="relative mt-2 h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-2xl bg-white shadow-[0_10px_60px_-15px_rgba(0,0,0,0.3)] transition-[width,_height] duration-300 data-[state=closed]:animate-scaleOut data-[state=open]:animate-scaleIn sm:w-[var(--radix-navigation-menu-viewport-width)]" />
        </div>
      </NavigationMenu.Root>
    </div>
  )
}

export default Navigation
