/* eslint-disable react/prop-types */
import "../../styles/github-prism.css"
import React, { useEffect } from "react"
import clsx from "clsx"
import Prism from "prismjs"

import Balancer from "react-wrap-balancer"
import { useGhostPost } from "../../contexts/GhostPostContext"
import TableOfContent from "./TableOfContent"
import SubscribeDialog from "./SubscribeDialog"

const styles = {
  headings: "prose-headings:font-bold",
  h1: "prose-h1:text-4xl",
  h2: "prose-h2:text-3xl",
  h3: "prose-h3:text-xl",
  h4: "prose-h4:text-base",
  p: "prose-p:text-base prose-p:text-slate-600 prose-p:leading-7",
  a: "prose-a:decoration-slate-400 prose-a:underline-offset-2",
  blockquote: `prose-blockquote:relative
    prose-blockquote:text-blue-600
    prose-blockquote:p-0 prose-blockquote:text-2xl
    [&_a]:text-inherit
    [&_a]:font-inherit
    [&_strong]:text-inherit
    [&_em]:text-inherit
    [&_em]:font-inherit
    prose-blockquote:font-serif prose-blockquote:not-italic prose-blockquote:pl-6
    prose-blockquote:text-[1.0625rem] prose-blockquote:leading-7
    prose-blockquote:before:absolute prose-blockquote:before:top-0 prose-blockquote:before:-left-3
    prose-blockquote:border-l-4 prose-blockquote:border-solid prose-blockquote:border-blue-600
    prose-blockquote:my-0
    [&>.kg-blockquote-alt]:border-none [&>.kg-blockquote-alt]:text-2xl [&>.kg-blockquote-alt]:text-center
    [&>.kg-blockquote-alt]:before:content-['“'] [&>.kg-blockquote-alt]:before:relative
    [&>.kg-blockquote-alt]:after:content-[''] [&>.kg-blockquote-alt]:before:text-5xl
    [&>.kg-blockquote-alt]:before:block [&>.kg-blockquote-alt]:before:leading-[0.5]
    [&>.kg-blockquote-alt>a]:font-inherit
    md:[&>.kg-blockquote-alt]:px-10 md:[&>.kg-blockquote-alt]:py-16
    [&>.kg-blockquote-alt]:px-5 [&>.kg-blockquote-alt]:py-8`,
  ul: `marker:content-['—']`,
  li: `prose-li:text-base prose-li:pl-4`,
  pre: `prose-pre:scrollbar-thin`,
  listMarker: `marker:text-base marker:text-brand-green-200 marker:pl-4`,
  table: `prose-table:my-6 prose-table:w-full prose-table:border-collapse prose-table:block prose-table:overflow-x-auto prose-table:lg:table`,
  thead: `prose-thead:bg-slate-50 prose-thead:w-full`,
  tbody: `prose-tbody:w-full`,
  tr: `even:prose-tr:bg-slate-50`,
  th: `prose-th:text-sm prose-th:px-4 prose-th:py-3 prose-th:border-collapse`,
  td: `prose-td:text-sm prose-td:p-4 prose-td:border-collapse`,
  kgCard: `[&_.kg-callout-card]:pl-6 [&_.kg-callout-card]:pr-8
           [&_.kg-callout-card]:py-7 [&_.kg-callout-text]:text-base
           [&_.kg-callout-card>.kg-callout-text]:ml-2
           [&_.kg-callout-card>.kg-callout-emoji]:w-7
           [&_.kg-callout-card>.kg-callout-emoji]:mr-2
           [&_.kg-callout-card>.kg-callout-emoji]:p-0
           [&_.kg-callout-card>.kg-callout-emoji]:text-center
           [&_.kg-callout-card>.kg-callout-emoji]:flex
           [&_.kg-callout-card>.kg-callout-emoji]:justify-center
           [&_.kg-callout-card]:font-serif [&_.kg-callout-card]:text-slate-600
           [&_.kg-callout-card]:border [&_.kg-callout-card]:border-solid
           [&_.kg-callout-card-grey]:border-slate-300 [&_.kg-callout-card-grey]:bg-slate-50
           [&_.kg-callout-card-white]:border-slate-300 [&_.kg-callout-card-white]:bg-white
           [&_.kg-callout-card-blue]:border-blue-300 [&_.kg-callout-card-blue]:bg-blue-50
           [&_.kg-callout-card-green]:border-emerald-400 [&_.kg-callout-card-green]:bg-green-50
           [&_.kg-callout-card-yellow]:border-yellow-400 [&_.kg-callout-card-yellow]:bg-yellow-50
           [&_.kg-callout-card-red]:border-red-300 [&_.kg-callout-card-red]:bg-red-50
           [&_.kg-callout-card-pink]:border-pink-300 [&_.kg-callout-card-pink]:bg-pink-50
           [&_.kg-callout-card-purple]:border-purple-300 [&_.kg-callout-card-purple]:bg-purple-50
           [&_.kg-callout-card-accent]:border-transparent [&_.kg-callout-card-accent]:bg-brand-blue-100
           [&_.kg-callout-card-accent>.kg-callout-text]:text-slate-200`,
  bookmark: `[&_.kg-bookmark-card>.kg-bookmark-container]:flex
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-content]:basis-3/4
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-thumbnail]:basis-1/4
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-thumbnail>img]:m-0
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-thumbnail>img]:border-none
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-thumbnail>img]:rounded-none
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-content]:flex
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-content]:flex-col
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-content>.kg-bookmark-description]:flex-1
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-content>.kg-bookmark-description]:text-base
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-content>.kg-bookmark-description]:leading-[1.75]
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-content>.kg-bookmark-title]:text-base
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-content>.kg-bookmark-title]:leading-[1.75]
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-content>.kg-bookmark-metadata>.kg-bookmark-icon]:my-0
             [&_.kg-bookmark-card>.kg-bookmark-container>.kg-bookmark-content>.kg-bookmark-metadata>.kg-bookmark-icon]:border-none`,
  figure: `[&_figcaption]:text-slate-400 [&_figcaption]:text-center [&_figcaption]:font-serif [&_figcaption]:text-sm
           [&_figcaption]:mt-2 [&_figcaption]:mx-auto [&_figcaption]:p-0 [&_figcaption]:leading-7 [&_figcaption]:w-9/12
           [&_.kg-image]:!max-h-[auto] [&_.kg-image]:!h-[auto]
           [&_.kg-card.kg-embed-card>div]:flex [&_.kg-card.kg-embed-card>div]:justify-center [&_.kg-embed-card]:!min-h-[200px] sm:[&_.kg-embed-card]:!min-h-[387px] md:[&_.kg-embed-card]:!min-h-[396px] 
           ` /** Min-height is required for the youtube embed to show up */,
}

const Content = ({ showToc = true }) => {
  const { html } = useGhostPost()
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    Prism.highlightAll()
  }, [])

  return (
    <>
      <SubscribeDialog open={open} setOpen={setOpen} />
      <div className="col-start-1 col-end-13 flex justify-items-stretch lg:col-start-1 lg:col-end-9 lg:row-start-3 lg:row-end-5">
        <div
          className={clsx(
            "js-toc-content prose mt-0 w-full min-w-full prose-ul:marker:content-['—'] [&_*]:break-words [&_a]:font-inherit",
            styles.headings,
            styles.h1,
            styles.h2,
            styles.p,
            styles.a,
            styles.blockquote,
            styles.listMarker,
            styles.ul,
            styles.li,
            styles.ol,
            styles.pre,
            styles.table,
            styles.thead,
            styles.tr,
            styles.th,
            styles.td,
            styles.kgCard,
            styles.bookmark,
            styles.figure,
          )}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        ></div>
      </div>
      {showToc && (
        <div className="relative col-start-1 col-end-13 row-start-3 row-end-5 lg:col-start-11">
          {/* change top-32 to top-28 once toc is fixed */}
          <div className="sticky top-32 flex flex-col">
            {/* <TableOfContent />
            <hr className="my-6" /> */}
            <div className="flex w-full flex-col gap-3 pt-3">
              <h4 className="text-sm font-bold uppercase text-slate-500">
                Newsletter
              </h4>
              <p className="text-balance text-sm text-slate-500">
                Stay updated on the latest from Last9.
              </p>
              <button
                className="w-fit rounded-full bg-brand-green-200 px-6 py-2.5 text-sm font-bold text-white"
                onClick={() => setOpen(true)}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Content
