import React from "react"
import headerStackImg from "../../../static/icons/header-stack.png"
import IconTune from "../../../static/logs/icon-tune.inline.svg"
import IconIngest from "../../../static/logs/icon-ingest.inline.svg"
import IconStandard from "../../../static/logs/icon-standard.inline.svg"

import imageTracesEverything from "../../../static/traces/traces-cost-effective-scale.png"
import imageTracesPipelines from "../../../static/traces/traces-pipelines.png"
import imageTracesIndustryStandard from "../../../static/traces/traces-industry-standard.png"

const LogManagement = ({}) => {
  return (
    <section className="scroll-pt-40 bg-gradient-to-b from-slate-50 to-transparent px-6 py-20">
      <div className="mx-auto flex w-full max-w-6xl flex-col gap-16">
        {/* section header */}
        <div className="flex flex-col-reverse items-start justify-between gap-5 md:flex-row md:items-center">
          <h2 className="max-w-lg text-balance text-4xl font-extrabold text-slate-900 md:text-5xl">
            Manage your traces data and its lifecycle at scale.
          </h2>
          <img alt="" className="h-[120px]" src={headerStackImg} />
        </div>
        {/* end section header */}

        {/* card 1 */}
        <div
          id="traces-management-trace-everything"
          className="flex w-full flex-col items-center justify-center gap-4 md:flex-row-reverse md:gap-12"
        >
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageTracesEverything}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconTune className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              All your traces always, at scale and cost-effective
            </h3>

            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                No sampling, gain granular correlated observability by ingesting
                and analyzing millions of traces per minute
              </li>
              <li>
                Simple pricing model, no per-host billing, no per-user billing
              </li>
              <li>
                Post 14-days default retention, cost-effectively retain traces
                for long-term on your AWS S3 with on-demand rehydration
              </li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 1 */}

        {/* card 2 */}
        <div
          id="traces-management-pipelines"
          className="flex w-full flex-col items-center justify-center gap-4 md:flex-row md:gap-12"
        >
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageTracesPipelines}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconIngest className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Auto-instrument your applications and ingest from 50+ integrations
            </h3>

            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>
                OpenTelemetry native, 50+ sources to ingest traces from, with
                auto instrumentation
              </li>
              <li>
                Define pre-ingestion rules to identify traces with matching
                patterns to filter, forward, or drop them — even before they’re
                stored in the Last9 telemetry warehouse
              </li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 2 */}

        {/* card 3 */}
        <div
          id="traces-management-industry-standards"
          className="flex w-full flex-col items-center justify-center gap-4 md:flex-row-reverse md:gap-12"
        >
          {/* image col */}
          <div className="flex md:basis-1/2">
            <img
              src={imageTracesIndustryStandard}
              className="aspect-[5/3] rounded-lg border border-solid border-slate-100"
            />
          </div>
          {/* end image col */}

          {/* text col */}
          <div className="flex flex-col gap-4 md:basis-1/2">
            <IconStandard className="h-6 w-6" />
            <h3 className="text-balance text-xl font-bold text-slate-600 lg:w-2/3 lg:text-2xl">
              Industry-standard privacy and security
            </h3>

            <ul className="list-disc space-y-1 pl-6 text-base text-slate-500 marker:mr-1 marker:text-sm marker:font-bold marker:text-emerald-600 marker:content-['__']">
              <li>Supports SOC2 Type 2 and PCI compliance</li>
              <li>Manage user access with allowlist and blocklist controls</li>
              <li>
                Pre-ingestion workflows to skip ingestion of sensitive
                information or label and directly forward to secure storage for
                security purposes
              </li>
            </ul>
          </div>
          {/* end text col */}
        </div>
        {/* end card 3 */}
      </div>
    </section>
  )
}

export default LogManagement
